<template>
    <div>
        <div v-if="authUserPermission['sap-sync-task-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('config_sap_sync') }}</h3>
                            </div>
                            <div class="col-6">
                                <base-input input-classes="form-control-sm text-center border-radius-20" v-model="sync.search" :placeholder="tt('search_config_sap_sync')" v-on:keyup="filter"/>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="sync.data" v-if="!onLoad">
                            <el-table-column :label="tt('id')" :prop="tt('id')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    {{row.id}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('sync_name')" :prop="tt('sync_name')" min-width="160px" sortable>
                                <template v-slot="{row}">
                                    {{row.sync_name}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('connection')" :prop="tt('connection_id')" min-width="160px" sortable>
                                <template v-slot="{row}">
                                    {{row.connection_id}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('function_type')" :prop="tt('function_type')" min-width="200px" sortable>
                                <template v-slot="{row}">
                                    {{row.function_type}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('function')" :prop="tt('function')" min-width="260px" sortable>
                                <template v-slot="{row}">
                                    {{row.function}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('source_type')" :prop="tt('source_type')" min-width="260px" sortable>
                                <template v-slot="{row}">
                                    {{row.source_type}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('source')" :prop="tt('source')" min-width="260px" sortable>
                                <template v-slot="{row}">
                                    {{row.source}}
                                </template>
                            </el-table-column>
                            <!-- <el-table-column :label="tt('last_sync_by')" :prop="tt('last_sync_by')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.last_sync_by}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('last_sync_at')" :prop="tt('last_sync_at')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.last_sync_at}}
                                </template>
                            </el-table-column> -->
                            <el-table-column prop="action">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'params', data:row.id}">{{ tt('params') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'edit', data:row.id}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'remove', data:row.id}">{{ tt('delete') }}</el-dropdown-item>                                                                                
                                    </el-dropdown-menu>
                                </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <skeleton-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="sync.page.last_page" v-model="sync.page.current_page" @click.native="changePage(sync.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (sync.page.current_page - 1) * sync.page.per_page + sync.data.length, total: sync.total}) }}
                        </span>
                    </div>
                </div>
            </div>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('id') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('id')" v-model="sapSync.id" :placeholder="tt('id')" rules="required" :disabled="!form.add">
                        </base-input>

                        <label class="form-control-label">{{ tt('sync_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('sync_name')" v-model="sapSync.sync_name" :placeholder="tt('sync_name')" rules="required">
                        </base-input>

                        <label class="form-control-label">{{ tt('connection_id') }}</label>
                        <base-input :name="tt('connection_id')">
                            <el-select v-model="sapSync.coonection_id" :placeholder="tt('connection_id')" filterable remote :remote-method="searchConnection" clearable :loading="loading">
                                <el-option v-for="connection in connections" :value="connection.id" :label="connection.connection" ></el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('function_type') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('function_type')" rules="required">
                            <el-select v-model="sapSync.function_type" :placeholder="tt('function_type')">
                                <el-option value="script">script</el-option>
                                <el-option value="PI">PI</el-option>
                                <el-option value="custom">custom</el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('function') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('function')" rules="required" v-model="sapSync.function" :placeholder="tt('function')"></base-input>

                        <label class="form-control-label">{{ tt('source_type') }}</label>
                        <base-input :name="tt('source_type')">
                            <el-select v-model="sapSync.source_type" :placeholder="tt('source_type')" clearable>
                                <el-option value="object">object</el-option>
                            </el-select>
                        </base-input>
                        
                        <label class="form-control-label" v-if="sapSync.source_type == 'object'">{{ tt('source') }} <span class="text-danger">*</span></label>
                        <base-input v-if="sapSync.source_type == 'object'" :name="tt('source')" :placeholder="tt('source')" v-model="sapSync.source" rules="required">
                            <el-select v-model="sapSync.source" :placeholder="tt('source')" filterable clearable remote :remote-method="searchObject" reserve-keyword :loading="loading">
                                <el-option v-for="object in tableObjects" :key="object.code" :value="object.code" :label="object.code"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import sapIntegration from '@/services/sap/sapIntegration.service';    
    import sapConnection from '@/services/sap/sapConnection.service';

    export default {        
        data() {
            return { 
                onLoad: true,      
                loadTimeout: null,  
                loading:false,
                btnSave: {
                    onLoading: false
                },    
                form: {
                    add: true,
                    title: this.tt('add_sap_sync_param'),
                    show: false
                },             
                sync: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',
                    data: [],
                    page:{
                        current_page:1,
                        per_page:100,
                    },
                    total:0
                },
                sapSync:{},
                sources:[],
                connections:[],
                tableObjects:[],
                
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, sapIntegration.configSync(context.sync.page.current_page,{search: context.sync.search})).onSuccess(function(response) {                        
                    context.sync.total = response.data.data.data.total;  
                    context.sync.data = response.data.data.data.data;  
                    context.sync.page = response.data.data.data;  
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.sync.data = [];  
                        context.sync.total = 0;                      
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            filter() {
                if(this.sync.search != this.$route.query.search){
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.sync.search == ''){
                            this.$router.push({path:'/sap/config-sap-sync', query:null})
                        }else{
                            this.$router.push({path:'/sap/config-sap-sync', query:{search:this.sync.search}})
                        }
                        this.get()
                    }, 100);
                }
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            handleTableAction(command){
                switch(command.action){
                    case 'params' :
                          this.getParams(command.data);
                    break;
                    case 'edit'   : 
                        this.edit(command.data)
                    break;
                }
            },
            getParams(sapSyncId){
                this.$router.push({path:'/sap/config-sap-sync-params',query:{sap_sync_id:sapSyncId}})
            },
            searchObject(query){
                if(query.length > 0){
                    setTimeout(() => {
                        let context = this;
                        this.loading = true;
                        Api(context, sapIntegration.getTableObjects({search:query})).onSuccess((response) =>{
                            context.tableObjects = response.data.data
                            context.loading = false;
                        }).onError((e) =>{
                            context.tableObjects = [];
                        }).call()
                    }, 200)
                }
            },
            searchConnection(query){           
                if(query.length > 0){
                    setTimeout(() =>{
                        let context = this;    
                        this.loading = false;
                        Api(context, sapConnection.get(null, {search: query})).onSuccess(function(response) {    
                            context.connections  = response.data.data.data.data;  
                             context.loading = false;    
                        }).onError(function(error) {                    
                            if (error.response.status == 404) {
                                context.connections = [];
                            }
                        })
                        .call()
                    }, 200)
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_sap_sync');
                this.form.show = true;
                this.sapSync = {};
            },
            save(){
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;
                if (this.form.add) {
                    api = Api(context, sapIntegration.createSync(this.sapSync));
                } else {
                    api = Api(context, sapIntegration.updateSync(this.sapSync));
                }
                api.onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                    context.get()
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            edit(id){
                this.errorMessage = null;
                let context = this;               
                Api(context, sapIntegration.showConfigSync({id : id})).onSuccess(function(response) {
                    context.sapSync = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_sap_sync');
                    context.form.show = true;                          
                })
                .call() 
            }
        }   
    };
</script>
<style></style>
